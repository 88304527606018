import React from "react";
import { Link } from "react-router-dom";

const HomeFooter = () => {
  return (
    <>
      <footer>
        <div className="footer-top primary-bg pt-115 pb-90">
          <div className="container">
            <div className="row">
              <div className="col-xl-3 col-lg-5 col-md-8">
                <div className="footer-contact-info mb-30">
                  <div className="emmergency-call fix">
                    <div className="emmergency-call-icon f-left"></div>
                    <div className="emmergency-call-text f-left">
                      <h6 className="mb-20">
                        Número de Emergência <i className="fas fa-phone"></i>
                      </h6>

                      <h4 className="mt-20">(11)9 5879-9090</h4>
                    </div>
                  </div>
                  <div className="footer-logo mb-35">
                    <Link to="/">
                      <img src="img/logo/logo.png" alt="" />
                    </Link>
                  </div>
                  <div className="footer-contact-content mb-25">
                    <p>Sua Jornada de Saúde Feminina Começa Aqui</p>
                  </div>
                  <div className="footer-emailing">
                    <ul>
                      <li>contato@olismulher.com</li>

                      <li>47.205.725/0001-00</li>
                    </ul>
                  </div>
                </div>
              </div>
              <div className="col-xl-2 offset-xl-1 col-lg-2 col-md-4">
                <div className="footer-widget mb-30">
                  <div className="footer-title">
                    <h3>Ginecologia</h3>
                  </div>
                  <div className="footer-menu">
                    <ul>
                      <li>
                        <Link to="/primeiraConsulta">Primeira Consulta</Link>
                      </li>
                      <li>
                        <Link to="/rotinaGinecologica">
                          Rotina Ginecológica
                        </Link>
                      </li>
                      <li>
                        <Link to="/anticoncepcao">Anticoncepção</Link>
                      </li>
                      <li>
                        <Link to="/investigacaoInfertilidade">
                          Investigação de Infertilidade
                        </Link>
                      </li>
                      <li>
                        <Link to="/reposicaoHormonal">Reposição Hormonal</Link>
                      </li>
                      <li>
                        <Link to="/avaliacaoFertilidade">
                          Avaliação de Fertilidade
                        </Link>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              <div className="col-xl-2 offset-xl-1 col-lg-2 d-md-none d-lg-block">
                <div className="footer-widget mb-30">
                  <div className="footer-title">
                    <h3> Obstetrícia</h3>
                  </div>
                  <div className="footer-menu">
                    <ul>
                      <li>
                        <Link to="/consultoriaPreNatal">
                          Consultoria Pré-Natal
                        </Link>
                      </li>
                      <li>
                        <Link to="/suplementacaoNaGestacao">
                          Suplementação na Gestação
                        </Link>
                      </li>
                      <li>
                        <Link to="/suplementacaoPosParto">
                          Suplementação Pós-Parto
                        </Link>
                      </li>
                      <li>
                        <Link to="/diabetesGestacional">
                          Diabetes Gestacional
                        </Link>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              <div className="col-xl-2 offset-xl-1 col-lg-2 d-md-none d-lg-block">
                <div className="footer-widget mb-30">
                  <div className="footer-title">
                    <h3> Links úteis</h3>
                  </div>
                  <div className="footer-menu">
                    <ul>
                      {/* Politica de Privacidade, Cancelamento, Termos de Uso, Termos Gerais
                       */}
                      <li>
                        <Link to="/politicaCancelamento">
                          Política de Cancelamento
                        </Link>
                      </li>
                      <li>
                        <Link to="/politicaPrivacidade">
                          Política de Privacidade
                        </Link>
                      </li>
                      <li>
                        <Link to="/termoConsentimento">
                          Termo de Consentimento
                        </Link>
                      </li>
                      <li>
                        <Link to="/termosGerais">Termos Gerais</Link>
                      </li>

                      <li>
                        <a href="https://www.instagram.com/olismulher/">
                          Instagram
                        </a>
                      </li>
                      {/* <li>
                        <i className="fa-brands fa-facebook"></i>
                        <Link to="/sobre">Facebook</Link>
                      </li> */}
                      {/* <li>
                        <i className="fa-brands fa-linkedin"></i>
                        <Link to="/sobre">LinkedIn</Link>
                      </li> */}
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* <div className="footer-bottom pt-25 pb-20">
          <div className="container">
            <div className="row">
              <div className="col-xl-12">
                <div className="footer-copyright text-center">
                  <p className="white-color">Copyright by@ BasicTheme - 2022</p>
                </div>
              </div>
            </div>
          </div>
        </div> */}
      </footer>
    </>
  );
};

export default HomeFooter;
