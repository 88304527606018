import React from "react";
import { Link } from "react-router-dom";

const PrivacyPolicy = () => {
  return (
    <>
      <section
        className="blog-area pt-120 pb-80"
        style={{ marginBottom: "140px" }}
      >
        <div className="container">
          <div className="row">
            <h1>POLÍTICA DE PRIVACIDADE DA OLIS SAÚDE</h1>

            <p>
              A OLIS SAÚDE FEMININA LTDA., com sede na Rua Augusta, no 890,
              Consolação, São Paulo/SP, inscrita no CNPJ sob o no
              47.205.725/0001-80 oferece a você acesso a um portfólio de
              funcionalidades, produtos e serviços (“Serviços”) por meio do site{" "}
              <a href="http://www.olismulher.com">https://olismulher.com/</a>,
              de acordo com os TERMOS DE USO.
            </p>

            <p>
              À medida em que você nos contate, se cadastre, acesse ou utilize
              as Plataformas ou contrate Serviços, informações pessoais sobre
              você poderão ser solicitadas ou coletadas. A presente política de
              privacidade (“Política de Privacidade”) descreve quais os tipos de
              Dados Pessoais que podemos receber sobre você, diretamente ou por
              meio de sua interação conosco, como podemos usá-los, com quem
              podemos compartilhá-los, como os protegemos e os mantemos seguros
              e seus direitos com relação aos seus Dados Pessoais.
            </p>

            <p>
              É claro que nem todas as situações serão aplicáveis a você. Esta
              Política de Privacidade fornece uma visão geral de todas as
              situações possíveis por meio das quais podemos interagir, por isso
              é importante que seja lida para compreensão sobre a finalidade do
              tratamento que será atribuído aos seus dados pessoais.
            </p>

            <p>
              Assim ao acessar e ler esta política você declara que compreende e
              concorda que OLIS SAÚDE poderá coletar, armazenar, processar,
              associar, compartilhar, utilizar e divulgar ou de qualquer outra
              forma tratar as suas informações e dados pessoais, conforme
              previsto nesta Política de Privacidade.
            </p>

            <p>
              A OLIS SAÚDE respeita e zela pela privacidade dos Titulares de
              Dados Pessoais, buscando ser transparente e prover informações
              claras sobre suas práticas de Tratamento de dados em observância
              às regras estabelecidas pela Lei no 13.709/2018. Nós estamos
              comprometidos em zelar pelo sigilo de suas informações, sobretudo
              as médicas e de saúde. O Tratamento de suas informações pessoais
              visa, antes de tudo, a proporcionar um atendimento melhor e mais
              personalizado, que leve em conta as condições específicas de cada
              Usuário. Quanto mais você interage conosco, mais nos informa e
              mais capazes somos de lhe oferecer serviços personalizados.
            </p>

            <p>
              Para fins desta Política de Privacidade, “Usuário” ou “você”
              significa todo e qualquer indivíduo que faça uso ou tenha acesso
              às Plataformas ou ao seu conteúdo ou que contrate os Serviços.
            </p>

            <address>
              Rua Augusta, no 890, Consolação, São Paulo/SP CEP 01304-001
            </address>

            <h2>Esta Política de Privacidade está dividida em 10 seções:</h2>

            <ol>
              <li>Definições;</li>
              <li>Coleta e Tratamento de Dados Pessoais;</li>
              <li>Finalidades de Uso e Compartilhamento;</li>
              <li>Sobre Dados Sensíveis;</li>
              <li>Armazenamento, Duração e Segurança;</li>
              <li>
                Tratamento e Transferência Internacional de Dados Pessoais;
              </li>
              <li>Cookies;</li>
              <li>Direitos do Usuário;</li>
              <li>Alterações à Política de Privacidade; e</li>
              <li>Aceitação da Política de Privacidade.</li>
            </ol>

            <p>
              Esta Política de Privacidade foi atualizada pela última vez em
              agosto 2024.
            </p>

            <h2>1. DEFINIÇÕES</h2>

            <p>
              Algumas definições utilizadas ao longo desta Política de
              Privacidade:
            </p>

            <ul>
              <li>
                <strong>“Dados Pessoais”:</strong> dados que identifiquem ou
                tornem identificável uma pessoa natural.
              </li>
              <li>
                <strong>“Dados Pessoais Sensíveis”:</strong> Dados Pessoais
                sobre origem racial ou étnica, convicção religiosa, opinião
                política, filiação a sindicato ou a organização de caráter
                religioso, filosófico ou político, dado referente à saúde ou à
                vida sexual, dado genético ou biométrico, quando vinculado a uma
                pessoa natural.
              </li>
              <li>
                <strong>“Dados Anonimizados”:</strong> quaisquer dados relativos
                a um Titular que não possa ser identificado, considerando a
                utilização de meios técnicos razoáveis e disponíveis na ocasião
                de seu Tratamento.
              </li>
              <li>
                <strong>“Controlador”:</strong> pessoa física ou jurídica a quem
                competem as decisões referentes ao Tratamento de Dados Pessoais.
              </li>
              <li>
                <strong>“Operador”:</strong> pessoa física ou jurídica que
                realiza o Tratamento de Dados Pessoais em nome do Controlador.
              </li>
              <li>
                <strong>“Cookies”:</strong> arquivos de navegação que armazenam
                temporariamente o que o Usuário está visitando em um determinado
                site ou aplicativo.
              </li>
              <li>
                <strong>“Usuário” ou “você”:</strong> todo e qualquer indivíduo
                que faça uso ou tenha acesso às Plataformas ou ao seu conteúdo
                ou que contrate os Serviços.
              </li>
              <li>
                <strong>“Titular”:</strong> pessoa natural a quem se referem os
                Dados Pessoais que são objeto de Tratamento.
              </li>
              <li>
                <strong>“Tratamento”:</strong> qualquer operação ou conjunto de
                operações realizadas com Dados Pessoais ou conjuntos de Dados
                Pessoais, seja por meios automatizados ou não, tais como, mas
                não se limitando a, coleta, uso, acesso, organização, consulta,
                produção, alteração, recepção, classificação, utilização,
                reprodução, comunicação, transmissão, distribuição,
                processamento, arquivamento, registro, estruturação,
                armazenamento, adaptação, recuperação, transferência,
                disponibilização, combinação, restrição, eliminação, avaliação
                ou controle, modificação, eliminação ou extração.
              </li>
              <li>
                <strong>“Legislação de Proteção de Dados”:</strong> enquanto
                permanecer em vigor, a Lei Geral de Proteção de Dados Pessoais
                (Lei No 13.709/2018 ou “LGPD”), suas alterações posteriores, e
                quaisquer outras leis e regulamentos em relação ao tratamento,
                proteção e privacidade de Dados que sejam aplicáveis e, se
                aplicáveis, todas as orientações, normas, regras, portarias,
                regulamentos e códigos de prática e conduta emitidos pela
                Autoridade Nacional de Proteção de Dados (“ANPD”) ou outra
                autoridade de supervisão ou proteção de dados pertinente.
              </li>
              <li>
                <strong>“Violação de Dados Pessoais”:</strong> violação de
                segurança da informação que acarreta a destruição acidental ou
                ilegal, perda, alteração, divulgação ou acesso não autorizado de
                Dados Pessoais transmitidos, armazenados ou de outra forma
                Tratados pela OLIS SAÚDE ou por um subcontratado autorizado.
              </li>
              <li>
                <strong>“Consentimento”:</strong> manifestação livre, informada
                e inequívoca pela qual uma pessoa física concorda com o
                Tratamento de seus Dados Pessoais para uma finalidade
                determinada.
              </li>
            </ul>

            <h2>2. COLETA E TRATAMENTO DE DADOS PESSOAIS</h2>

            <p>
              A OLIS SAÚDE coleta e trata informações sobre você em conexão ao
              cadastro, acesso ou utilização das Plataformas ou contratação de
              Serviços. Essas informações incluem informações que você nos
              fornece diretamente (como dados de cadastro e identificação,
              informações inseridas por você nas Plataformas, e informações
              compartilhadas por você ao entrar em contato conosco ou interagir
              com outros Usuários); informações fornecidas por você ou coletadas
              por Nós quando você se cadastra, acessa ou utiliza as Plataformas
              ou contrata Serviços; informações coletadas do dispositivo que
              você utiliza para acessar as Plataformas; informações de
              localização; informações que recebemos de terceiros sobre você;
              informações de Cookies ou tecnologias assemelhadas, informações
              estatísticas e dados agregados. Algumas dessas informações nos
              permitem identificar você. Essas informações podem ser utilizadas
              nas situações e para as finalidades descritas abaixo.
            </p>

            <h3>Categorias De Dados Pessoais</h3>
            <p>
              As categorias de informações que poderão ser coletadas e tratadas
              são indicadas abaixo:
            </p>

            <ul>
              <li>
                <strong>Dados de Identificação e Cadastro:</strong> nome
                completo, endereço, número de telefone, e-mail, data de
                nascimento, idade, nome de Usuário, login e senha de acesso,
                dados de cartão de crédito ou outras informações de pagamento.
                Em geral, dados de identificação e cadastro são obrigatórios
                para o acesso às Plataformas e contratação de Serviços e serão
                solicitados a você.
              </li>
              <li>
                <strong>Dados de Perfil e Estilo de Vida:</strong> hábitos em
                relação a atividades físicas, hábitos sexuais (frequência e uso
                de preservativos), hábitos nutricionais, informações sobre o
                sono, informações sobre seu humor, informações sobre o uso de
                testes de ovulação e/ou de gravidez, informações sobre a
                quantidade de filhos que você tem ou deseja ter, informações
                sobre viagens, eventos e compromissos pessoais. Essas
                informações poderão ser solicitadas a você no contexto do
                cadastro, da utilização das Plataformas ou contratação de
                Serviços, e você poderá fornecê-las ou não, de maneira
                voluntária.
              </li>
              <li>
                <strong>Dados Médicos e de Saúde:</strong> histórico e
                antecedentes médicos, informações sobre exames e diagnósticos,
                informações de anamnese, consultas médicas, histórico familiar,
                queixas de saúde, sintomas, temperatura corporal, doenças, uso
                de métodos contraceptivos, uso de medicamentos, uso de
                suplementos, e informações relacionadas ao seu ciclo menstrual,
                tais como data da última menstruação, quanto tempo durou,
                periodicidade do ciclo, sintomas associados ao período
                menstrual, data de início dos sintomas, intensidade e
                consistência do fluxo, entre outros). Essas informações poderão
                ser solicitadas a você no contexto do cadastro, da utilização
                das Plataformas ou da contratação de Serviços, e você poderá
                fornecê-las ou não, de maneira voluntária.
              </li>
              <li>
                <strong>Dados Técnicos:</strong> dados de natureza técnica ou
                estatística conexos à utilização das Plataformas. Os dados
                técnicos incluem, dentre outras, informações sobre o uso das
                Plataformas (por exemplo, quando um aplicativo foi iniciado pela
                primeira vez), versão utilizada, identificador do dispositivo,
                modelos de hardware, endereço de IP, dados de rede, dados
                técnicos sobre o seu dispositivo, sistema operacional,
                preferências e configurações ativadas para a sua conta, tipo de
                Serviços solicitados ou fornecidos, funcionalidades das
                Plataformas usadas ou páginas visualizadas, falhas das
                Plataformas e outras atividades do sistema, tipo de navegador
                usado e sites ou serviços de terceiros que você usou antes de
                usar as Plataformas.
              </li>
              <li>
                <strong>
                  Informações coletadas por meio de Cookies e tecnologias
                  assemelhadas:
                </strong>{" "}
                A OLIS SAÚDE poderá utilizar Cookies e identificadores de
                dispositivos eletrônicos (como SDKs, web beacons, dentre outros)
                quando você utilizar as Plataformas. Essas tecnologias permitem
                análises estatísticas de uso de uma determinada aplicação,
                oferecimento ou desenvolvimento de funcionalidades, mas também
                podem permitir a identificação de dispositivos, navegadores e
                perfis de usuários. Também poderá configurar ou utilizar Cookies
                e tecnologias assemelhadas proprietárias ou de empresas
                terceiras para cumprir com as finalidades descritas. Os Cookies
                e tecnologias assemelhadas de empresas terceiras estão sujeitos
                às respectivas políticas de privacidade, e não à Política de
                Privacidade da OLIS SAÚDE. você poderá gerenciar suas opções de
                privacidade diretamente na política de privacidade de tais
                empresas terceiras.
              </li>
              <li>
                <strong>
                  Informações coletadas em bancos de dados de terceiros:
                </strong>{" "}
                A OLIS SAÚDE também poderá obter informações sobre você
                provenientes de bancos de dados públicos e privados, como
                parceiros comerciais ou outros prestadores de serviço, desde que
                suas informações tenham sido obtidas em conformidade com a
                Legislação de Proteção de Dados e possam ser compartilhadas com
                a OLIS SAÚDE. Essas informações poderão ser associadas às demais
                informações coletadas por nós ou fornecidas por você e também
                utilizadas nas situações e para as finalidades descritas. Nessa
                hipótese, somente as informações coletadas diretamente pela OLIS
                SAÚDE sobre você e o resultado dessa combinação são abrangidos
                por e sujeitos a esta Política de Privacidade.
              </li>
              <li>
                <strong>Conteúdo Gerado por você:</strong> Também realizamos o
                tratamento de informações suas quando você se comunica conosco
                ou interage com outros Usuários, por meio de perguntas,
                respostas, consultas, interações, diálogos, discussões, debates,
                fóruns, comentários, críticas, elogios, avaliação de sua
                experiência, feedback, depoimentos, sugestões, ou quando você
                compartilha conosco outras informações sobre você, ou sobre a
                sua experiência, fornecendo seu próprio conteúdo.
              </li>
              <li>
                <strong>Dados de Localização:</strong> Dependendo das
                configurações do seu aplicativo ou das permissões do seu
                dispositivo, a OLIS SAÚDE pode coletar informações sobre sua
                localização precisa ou aproximada, de acordo com dados de GPS,
                endereço IP e Wi-Fi. As informações sobre sua localização serão
                coletadas, quando as Plataformas estiverem em execução no seu
                dispositivo em primeiro plano (aplicativo aberto e exibido na
                tela) ou em segundo plano (aplicativo aberto, mas não exibido na
                tela). Os dados de localização são importantes para monitorarmos
                a qualidade dos Serviços e proteger você contra fraudes. você
                poderá desabilitar essa função diretamente no seu dispositivo
                caso não concorde com este Tratamento de dados.
              </li>
            </ul>

            <h2>3. FINALIDADES DE USO E COMPARTILHAMENTO</h2>

            <p>
              As suas informações, incluindo seus Dados Pessoais, são utilizadas
              para as seguintes finalidades:
            </p>

            <ul>
              <li>
                Disponibilizar, prestar, fornecer e manter, as Plataformas e os
                Serviços contratados;
              </li>
              <li>
                Personalizar, desenvolver e aprimorar as Plataformas e os
                Serviços, combinando e usando as informações que temos sobre
                você, como, por exemplo, a maneira que você interage com as
                Plataformas, os recursos que você mais utiliza, os conteúdos com
                os quais você mais interage e suas preferências de maneira
                geral;
              </li>
              <li>
                Proporcionar a você uma melhor visibilidade, consciência e
                conhecimento sobre seu corpo, seu estado de saúde, seu ciclo
                menstrual e sua fertilidade;
              </li>
              <li>
                Recomendar a você novos Serviços e novas jornadas e experiências
                nas Plataformas;
              </li>
              <li>
                Recomendar a você a realização de exames médicos complementares;
              </li>
              <li>
                Recomendar a você a realização de consultas médicas
                complementares, incluindo consultas presenciais e/ou de outras
                especialidades;
              </li>
              <li>
                Organizar e sistematizar seu perfil e informações de saúde,
                incluindo seu histórico e prontuário médico, com o propósito de
                prestar os Serviços de maneira mais eficiente e de acordo com a
                sua necessidade;
              </li>
              <li>
                Permitir aos profissionais de saúde que venham a atender você o
                acesso global e atualizado sobre seu histórico de saúde e
                prontuário médico, incluindo seus Dados Médicos e de Saúde e
                seus Dados de Perfil e Estilo de Vida;
              </li>
              <li>
                Para permitir a oferta e veiculação de publicidade, própria e de
                terceiros;
              </li>
              <li>
                Para nos comunicarmos com você, inclusive através de anúncios de
                serviços, notícias, informativos, recomendações, notificações,
                SMS, e-mail ou marketing relacionado às Plataformas, aos nossos
                Serviços ou a serviços de terceiros;
              </li>
              <li>
                Para cumprir com solicitações realizadas por você em relação às
                Plataformas ou aos Serviços, inclusive processar pagamentos;
              </li>
              <li>
                Para gerar análises e relatórios estatísticos agregados e
                anônimos sobre o funcionamento e operação das Plataformas e dos
                Serviços, em benefício da OLIS SAÚDE;
              </li>
              <li>
                Para realizar pesquisas de satisfação sobre as Plataformas e
                sobre os Serviços;
              </li>
              <li>
                Para realizar estudos e pesquisas científicas e de saúde
                populacional em formato agregado e anonimizado;
              </li>
              <li>
                Para detectar, identificar, impedir e se defender de atividades
                fraudulentas, abusivas ou ilegais;
              </li>
              <li>
                Para cumprir com obrigações legais e regulatórias de coleta,
                retenção e/ou compartilhamento de dados;
              </li>
              <li>
                Para o exercício de direitos da OLIS SAÚDE em processos
                administrativos, arbitragem ou judiciais, e para a defesa da
                OLIS SAÚDE, seus direitos, bens e interesses legítimos.
              </li>
            </ul>

            <h2>4. SOBRE DADOS SENSÍVEIS</h2>

            <p>
              A OLIS SAÚDE coleta e trata Dados Pessoais considerados Sensíveis
              pela Legislação de Proteção de Dados, incluindo os Dados Médicos e
              de Saúde, conforme definido acima. Os Dados Pessoais Sensíveis são
              tratados com um nível de cuidado ainda maior e para propósitos
              específicos. A OLIS SAÚDE adota medidas técnicas e organizacionais
              para proteger os Dados Pessoais Sensíveis, manter o sigilo médico
              e prevenir qualquer tipo de acesso ou utilização indevida,
              destruição, violação, corrupção ou divulgação não permitida de
              tais informações. Os Dados Pessoais Sensíveis estão sujeitos a um
              rígido controle de acesso e serão protegidos com criptografia de
              ponta-a-ponta.
            </p>

            <p>
              O Tratamento de Dados Pessoais Sensíveis objetiva atender
              prioritariamente o interesse do Usuário, para proporcionar a você
              uma melhor visibilidade, consciência e conhecimento sobre seu
              corpo, seu estado de saúde, seu ciclo menstrual e sua fertilidade,
              recomendar a você novos Serviços e novas jornadas e experiências
              nas Plataformas, recomendar a você a realização de exames e
              consultas médicas complementares, permitir aos profissionais de
              saúde que venham a atender você o acesso global e atualizado sobre
              seu histórico de saúde e prontuário médico, visando ao seu melhor
              atendimento, e para a prestação de Serviços mais eficientes e
              personalizados. A OLIS SAÚDE não realiza Tratamento de Dados
              Pessoais Sensíveis com o objetivo de realizar seleção de risco
              relacionada aos serviços.
            </p>

            <p>
              Também não realiza Tratamento de Dados Pessoais Sensíveis em
              conexão a estratégias comerciais, marketing, publicidade
              direcionada, comercialização de seus produtos ou serviços ou de
              terceiros. Poderemos utilizar Dados Pessoais Sensíveis de maneira
              agregada em estudos e pesquisas científicos e de saúde
              populacional que poderão orientar determinadas ações para melhorar
              a eficiência das Plataformas e dos seus Serviços, bem como para o
              cumprimento de obrigação legal ou regulatória, exercício regular
              de direitos da OLIS SAÚDE e/ou proteção da vida ou da incolumidade
              física do Usuário.
            </p>

            <p>
              <strong>
                COMPARTILHAMENTO DE DADOS DE SAÚDE E DADOS MÉDICOS:
              </strong>{" "}
              Nos casos autorizados pela lei ou mediante o consentimento
              específico do usuário, a OLIS SAÚDE poderá receber e compartilhar
              dados de saúde e dados médicos, com prestadores de serviço e
              profissionais da saúde de qualquer natureza, para as finalidades
              apontadas nos itens (iii), (vii), (viii), (xiv) e (xvi) do item 3,
              tópico “finalidade” desta política de privacidade. A OLIS SAÚDE
              disponibilizará meios de gestão adequados relacionados aos dados
              de saúde e dados médicos compartilhados.
            </p>

            <h2>5. ARMAZENAMENTO, DURAÇÃO E SEGURANÇA</h2>

            <h3>Armazenamento</h3>
            <p>
              As informações coletadas pela OLIS SAÚDE podem ser armazenadas
              diretamente por ela e/ou por prestadores de serviço da mesma.
              Adotamos padrões de segurança para a proteção da integridade,
              confidencialidade e disponibilidade das informações armazenadas
              conforme disposto abaixo.
            </p>

            <h3>Por quanto tempo mantemos suas informações?</h3>
            <p>
              Somente guardamos os seus Dados Pessoais pelo tempo que for
              necessário para atingir a finalidade para a qual realizamos o
              Tratamento, para atender às suas necessidades, ou para cumprir
              nossas obrigações legais ou regulatórias.
            </p>

            <p>
              Assim, arquivaremos e deixaremos de usar ativamente qualquer Dado
              Pessoal dentro de 36 meses, a contar da última vez que você
              utilizou os nossos Serviços e Plataformas. Quando não precisarmos
              mais usar seus Dados Pessoais, eles serão removidos de nossos
              sistemas e registros ou anonimizados, para que você não possa mais
              ser identificado a partir desses dados.
            </p>

            <p>
              Podemos reter alguns Dados Pessoais para além do período indicado
              acima para cumprir nossas obrigações legais ou regulatórias, bem
              como para permitir e garantir o exercício regular de nossos
              direitos (por exemplo, em processo judicial, administrativo ou
              arbitral).
            </p>

            <p>
              Para fins de auditoria, segurança, controle de fraudes e
              preservação de direitos, poderemos permanecer com o histórico de
              registro dos Dados Pessoais por prazo maior nas hipóteses que a
              lei ou norma regulatória assim estabelecer ou para preservação de
              direitos.
            </p>

            <h3>Onde armazenamos suas informações?</h3>
            <p>
              Os dados que coletamos sobre você podem ser transferidos para,
              acessados de e/ou armazenados em um local fora do Brasil. Podem
              também ser tratados por funcionários fora do Brasil, que trabalham
              para um de nossos prestadores de serviços.
            </p>

            <p>
              Apenas transferimos seus Dados Pessoais para fora do Brasil de
              maneira segura e em conformidade com a Legislação de Proteção de
              Dados. Na maioria das vezes, eventual transferência será feita
              para países ou organismos internacionais que proporcionem grau de
              proteção de Dados Pessoais adequado ao previsto na Legislação de
              Proteção de Dados.
            </p>

            <p>
              Como alguns países podem não ter legislação adequada que
              regulamente o tratamento e a transferência internacional de Dados
              Pessoais, Nós adotamos medidas para nos assegurarmos que os
              terceiros cumpram os compromissos estabelecidos nesta Política de
              Privacidade. Essas medidas podem incluir a análise das normas de
              privacidade e segurança dos terceiros e/ou a celebração de
              contratos apropriados, com cláusulas específicas sobre o
              Tratamento de Dados Pessoais.
            </p>

            <h3>Tratamento de Dados Pessoais</h3>
            <p>
              O uso das informações coletadas e/ou armazenadas exige o
              Tratamento de dados que poderá ser feito pela própria OLIS SAÚDE
              ou por prestadores de serviço contratados pela OLIS SAÚDE e que
              atuem em seu benefício para esse fim, que também estarão sujeitos
              a esta Política de Privacidade.
            </p>

            <h3>Segurança</h3>
            <p>
              A OLIS SAÚDE leva a segurança de seus Dados Pessoais muito a
              sério. Protegemos seus Dados Pessoais durante transmissões usando
              criptografia, como Transport Layer Security (TLS). Os seus Dados
              Pessoais são armazenados por nós em sistemas de computador com
              acesso limitado mantidos em instalações que incluem medidas
              físicas de segurança. Os dados armazenados são todos
              criptografados, mesmo quando utilizamos o armazenamento de
              terceiros.
            </p>

            <p>
              A OLIS SAÚDE toma todas as medidas técnicas e organizacionais
              adequadas para garantir a segurança dos Dados Pessoais e a
              confiabilidade de seus funcionários, colaboradores, equipe,
              diretores e agentes que puderem ter acesso a, ou estar envolvidos
              no Tratamento desses Dados Pessoais, seguindo padrões de segurança
              comumente utilizados no mercado para garantir a segurança dos seus
              Dados Pessoais.
            </p>

            <p>
              No entanto, nenhum método de transmissão ou retenção de dados
              eletrônicos é plenamente seguro e pode estar sujeito a ataques
              externos. A OLIS SAÚDE não será responsável por prejuízos que
              derivem de atos de terceiros que utilizem de meios indevidos,
              fraudulentos ou ilegais para acessar as informações armazenadas
              nos servidores ou nos bancos de dados utilizados por ela e não
              poderá ser responsabilizada caso você tenha dado causa ao acesso
              não autorizado por terceiros.
            </p>

            <p>
              Caso ocorra uma Violação de Segurança na Plataforma, o Titular e a
              Autoridade Nacional de Proteção de Dados (ANPD) serão notificados
              da existência de tal incidente, e a OLIS SAÚDE informará:
            </p>

            <ul>
              <li>A natureza dos Dados Pessoais afetados;</li>
              <li>Informações sobre os Titulares envolvidos;</li>
              <li>
                As medidas técnicas e de segurança utilizadas para a proteção
                dos dados, observados os segredos comercial e industrial;
              </li>
              <li>Os riscos relacionados ao incidente;</li>
              <li>
                As medidas que foram ou que serão adotadas para reverter ou
                mitigar os efeitos do prejuízo.
              </li>
            </ul>

            <p>
              As medidas de segurança acima descritas aplicam-se às suas
              informações somente a partir do momento em que a OLIS SAÚDE as
              recebe e enquanto as mantém sob sua guarda. O funcionamento e a
              segurança do dispositivo que você usa para acessar as Plataformas,
              bem como redes terceiras pelas quais os dados trafegam não são de
              nossa responsabilidade.
            </p>

            <p>
              Nada do que está escrito na Política de Privacidade tem como
              intenção excluir ou limitar qualquer condição, garantia, direito
              ou responsabilidade que não possa ser legalmente excluída ou
              limitada. Algumas jurisdições não permitem a exclusão de certas
              garantias ou condições ou a limitação ou exclusão de
              responsabilidade por perdas e danos. Consequentemente, apenas as
              limitações que forem permitidas por lei na sua jurisdição se
              aplicarão a você. Nos casos em que exclusão de responsabilidade
              não se mostrar possível, mas a limitação de responsabilidade for
              legalmente aplicável, a responsabilidade total da OLIS SAÚDE
              estará limitada a R$ 1.000,00 (mil Reais).
            </p>

            <h2>
              6. TRATAMENTO E TRANSFERÊNCIA INTERNACIONAL DE DADOS PESSOAIS
            </h2>

            <p>
              A OLIS SAÚDE poderá tratar seus dados pessoais, incluindo o
              armazenamento, no Brasil ou no exterior. Em qualquer caso, irá
              adotar as providências necessárias para garantir a observância
              desta Política de Privacidade e da legislação de proteção de dados
              de forma a garantir seus direitos relacionados à proteção de dados
              pessoais quando o tratamento de dados pessoais ocorrer fora do
              Brasil.
            </p>

            <p>
              Você autoriza, nos termos desta política de privacidade, a
              transferir, tratar, armazenar e utilizar seus dados pessoais,
              incluindo dados sensíveis em outros países.
            </p>

            <p>
              Para mais informações, <a href="#">clique aqui</a>.
            </p>

            <h2>7. COOKIES</h2>

            <p>
              Nós usamos Cookies quando você interage conosco. Esses podem ser
              Cookies de 'sessão', o que significa que eles são deletados quando
              você sai das Plataformas, ou Cookies 'persistentes', que não são
              deletados e nos ajudam a reconhecê-lo (mesmo sem o identificar)
              quando você retornar, para que possamos fornecer um serviço
              personalizado.
            </p>

            <p>
              Por exemplo, conhecer seu primeiro nome nos permitirá recebê-lo em
              sua próxima visita à sua área de Usuário. Saber que alguém que usa
              seu computador ou dispositivo usou um Serviço específico nos ajuda
              a tornar nossa publicidade e comunicações eletrônicas mais
              relevantes para seus interesses.
            </p>

            <p>
              Usamos esses dados para conhecer e analisar tendências,
              administrar as Plataformas e os Serviços, rastrear o comportamento
              do Usuário, melhorar nossos produtos e serviços e coletar
              informações demográficas sobre todos os nossos Usuários. A OLIS
              SAÚDE pode usar essas informações para serviços de marketing e
              publicidade.
            </p>

            <p>
              Os principais navegadores utilizados para acesso à web possuem
              opções para recusar o armazenamento de Cookies e ferramentas para
              controle de permissões e coleta de Dados Pessoais como, por
              exemplo, modos de navegação privativa ou anônima. Caso seja do seu
              interesse, você pode ajustar as configurações do seu navegador
              para recusar e/ou apagar Cookies. A função “Ajuda” do seu
              navegador deve informar como fazê-lo.
            </p>

            <p>
              Ainda, existem diversas extensões gratuitas para navegadores que
              permitem o bloqueio de anúncios personalizados e rastreamento por
              meio de Cookies. A OLIS SAÚDE não endossa ou verifica quaisquer
              dessas aplicações, sendo seu uso de sua exclusiva
              responsabilidade.
            </p>

            <p>
              Note-se que, ao desativar Cookies, você pode impedir que alguns
              serviços da web funcionem corretamente, afetando, parcial ou
              totalmente, a sua navegação.
            </p>

            <h2>8. DIREITOS DO USUÁRIO</h2>

            <p>
              Garantimos a você a possibilidade de atualizar seus Dados Pessoais
              sempre que necessário. Ainda, conforme determinado pela Legislação
              de Proteção de Dados, você tem direito a requerer:
            </p>

            <ul>
              <li>Confirmação da existência de Tratamento;</li>
              <li>Acesso aos seus Dados Pessoais;</li>
              <li>
                Correção de dados incompletos, inexatos ou desatualizados;
              </li>
              <li>
                Anonimização, bloqueio ou eliminação de dados desnecessários,
                excessivos ou tratados em desconformidade com o disposto na
                Legislação de Proteção de Dados;
              </li>
              <li>
                Solicitação da portabilidade de seus Dados Pessoais a outro
                fornecedor de serviço ou produto;
              </li>
              <li>
                Eliminação dos Dados Pessoais obtidos apenas com base em seu
                Consentimento;
              </li>
              <li>
                Revogação de seu consentimento e exclusão dos Dados Pessoais nos
                termos da lei;
              </li>
              <li>
                Informação sobre as entidades públicas e privadas com as quais a
                OLIS SAÚDE realizou o uso compartilhado de seus Dados Pessoais.
              </li>
            </ul>

            <p>
              <strong>Contato:</strong> Caso você queira exercer algum desses
              direitos, deseje obter maiores esclarecimentos sobre a aplicação
              desta Política de Privacidade, ou considere que o Tratamento de
              seus Dados Pessoais não está em conformidade com a Legislação de
              Proteção de Dados, entre em contato conosco, através do e-mail{" "}
              <a href="mailto:contato@mulherolis.com.br">
                contato@mulherolis.com.br
              </a>
              . Teremos a satisfação de esclarecer-lhe eventuais dúvidas e/ou
              atender à sua solicitação.
            </p>

            <p>
              Em observância à legislação aplicável ou cumprimento de ordem
              judicial, a OLIS SAÚDE poderá manter determinados dados pessoais
              seus armazenados por um período não inferior a 6 (seis) meses,
              após o seu pedido de exclusão. Referidos dados não serão
              anonimizados ou destruídos antes da conclusão desse prazo. A sua
              solicitação de exclusão será armazenada e, observado o prazo legal
              de guarda obrigatória de determinados dados, providenciará a
              destruição ou anonimização, conforme critério próprio e exclusivo,
              das informações capazes de identificar você.
            </p>

            <p>
              Caso você solicite a exclusão das suas informações, mas ainda
              tenha alguma obrigação pendente de cumprimento com a OLIS SAÚDE,
              as suas informações não serão excluídas e permanecerão armazenadas
              para o fim de viabilizar a solução da pendência e a adoção de
              medidas cabíveis.
            </p>

            <h2>9. ALTERAÇÕES À POLÍTICA DE PRIVACIDADE</h2>

            <p>
              Esta Política de Privacidade poderá ser alterada de tempos em
              tempos, portanto, recomendamos que você a acesse e a reveja
              periodicamente. Em caso de modificações substantivas, incluindo
              mudanças nas finalidades de Tratamento de Dados Pessoais, a OLIS
              SAÚDE comunicará os Usuários de maneira antecipada sobre tais
              modificações por meio dos canais de contato disponíveis.
            </p>

            <p>
              Exceto quando o consentimento expresso for obrigatório, a
              continuidade da utilização das Plataformas e dos Serviços e/ou a
              falta de oposição ou revogação do consentimento do Usuário
              implicará na aceitação de eventuais modificações nesta Política de
              Privacidade.
            </p>

            <h2>10. ACEITAÇÃO DA POLÍTICA DE PRIVACIDADE</h2>

            <p>
              Ao aceitar esta política de privacidade você declara ter a
              capacidade legal, dando o seu consentimento expresso para os
              termos nela constantes.
            </p>

            <address>
              Rua Augusta, no 890, Consolação, São Paulo/SP CEP 01304-001
            </address>
          </div>
        </div>
      </section>
    </>
  );
};

export default PrivacyPolicy;
