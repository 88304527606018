import React, { useState } from "react";
import HomeThreeSingleFaq from "../../../components/HomeThreeSingleFaq/HomeThreeSingleFaq";

const HomeThreeFaq = () => {
  const [openFaqIndex, setOpenFaqIndex] = useState(null);

  const handleToggle = (index) => {
    setOpenFaqIndex((prevIndex) => (prevIndex === index ? null : index));
  };

  const faqData = [
    {
      number: "One",
      condition: true,
      secondClassAdd: "show",
      question: "O que significa ser uma mulher Olis?",
      answer: `
        <p><strong>Olis</strong> são mulheres autoconfiantes, fortes, que não têm medo de viver. Sua personalidade dinâmica mostra que uma mulher pode ser muitas coisas - poderosa, motivada, vulnerável e sensível - e que as emoções devem ser sentidas e expressas. <strong>Olis</strong> são mulheres fortes, que vivem seu poder de escolha. Umas querem ter filhos, outras não, e outras optaram por postergar a maternidade. Sozinhas ou acompanhadas, <strong>Olis</strong> vivem intensamente na busca por seus sonhos.</p>
      `,
    },
    {
      number: "Two",
      fistClassAdd: "collapsed",
      question: "O que é a plataforma Olis e como ela pode me ajudar?",
      answer: `
        <p>A <strong>Olis</strong> é uma plataforma dedicada à mulher moderna que precisa de praticidade no seu dia a dia, oferecendo saúde de forma <strong>objetiva</strong>, <strong>personalizada</strong> e <strong>descomplicada</strong>.</p>
        <p>Por meio da <strong>telemedicina</strong>, conectamos profissionais e pacientes na jornada por saúde, bem-estar e qualidade de vida. Com um olhar personalizado, a <strong>Olis</strong> oferece serviços diversos, na comodidade da sua casa, sem necessidade de:</p>
          filas de espera, deslocamentos, perda excessiva do seu valioso tempo.`,
    },
    {
      number: "Three",
      fistClassAdd: "collapsed",
      question: "Como agendar uma consulta na plataforma Olis?",
      answer: `
        <p>Agendar uma consulta na <strong>Olis</strong> é simples e rápido:</p>
        <ul>
          <li>- Escolha o serviço desejado</li>
          <li>- Selecione um horário conveniente</li>
          <li>- Preencha o formulário com suas informações</li>
          <li>- Realize o pagamento</li>
          <li>- Receba o link para a consulta por videochamada.</li>
        </ul>
      `,
    },
    {
      number: "Four",
      fistClassAdd: "collapsed",
      question: "Que tipo de serviços a Olis oferece?",
      answer: `
        <p>A <strong>Olis</strong> oferece uma ampla variedade de serviços de saúde para mulheres, incluindo:</p>
        <ol>
          <li>Consultas ginecológicas de rotina,</li>
          <li>Primeira consulta ginecológica para adolescentes,</li>
          <li>Escolha do método contraceptivo,</li>
          <li>Avaliação de fertilidade,</li>
          <li>Investigação da infertilidade,</li>
          <li>Reposição hormonal.</li>
          <li>Consultoria pré-natal,</li>
          <li>Suplementação gestacional e pós-parto,</li>
          <li>Suporte para diabetes gestacional.</li>
        </ol>
        <p>Todos os serviços são realizados por profissionais de saúde <strong>especialistas</strong> e <strong>humanizados</strong>.</p>
      `,
    },
    {
      number: "Five",
      fistClassAdd: "collapsed",
      question: "Uma consulta online é segura e eficaz?",
      answer: `
        <p><strong>Sim</strong>, nossas consultas online são seguras e eficazes.</p>
        <p>Utilizamos plataformas de videochamada seguras para garantir a <strong>privacidade</strong> e a <strong>confidencialidade</strong> de todas as informações.</p>
        <p>Nossos profissionais de saúde são treinados para oferecer atendimento de <strong>alta qualidade</strong> de forma remota, garantindo que você receba o cuidado necessário sem precisar sair de casa.</p>
      `,
    },
    {
      number: "Six",
      fistClassAdd: "collapsed",
      question: "Como devo me preparar para a consulta online?",
      answer: `
        <p>Para se preparar para a consulta online, siga estas orientações:</p>
        <ol>
          <li>Escolha um local tranquilo, privado e bem iluminado para a consulta,</li>
          <li>Certifique-se de que haja boa conexão à internet para evitar interrupções,</li>
          <li>Utilize um dispositivo (computador, tablet ou smartphone) com câmera e microfone funcionais,</li>
          <li>Faça um teste antes da consulta para garantir que tudo esteja operando corretamente,</li>
          <li>Tenha à mão seu histórico médico atualizado,</li>
          <li>Envie antecipadamente seus exames prévios, se houverem, para avaliação no nosso e-mail <strong>contato@mulherolis.com</strong>.</li>
        </ol>
      `,
    },
    {
      number: "Seven",
      fistClassAdd: "collapsed",
      question: "Como pode ser feito o pagamento da sua consulta?",
      answer: `
        <p>Aceitamos pagamento por <strong>PIX</strong> à vista e parcelamos em até <strong>6x no cartão de crédito</strong>.</p>
        <p>Nossos serviços podem ser reembolsados, caso você tenha plano de saúde (consulte condições com o seu convênio médico).</p>
      `,
    },
    {
      number: "Eight",
      fistClassAdd: "collapsed",
      question: "Como funciona o suporte pós-consulta?",
      answer: `
        <p>Após a consulta, você receberá todas as recomendações e prescrições possíveis via <strong>WhatsApp</strong> ou e-mail.</p>
        <p>Além disso, oferecemos acompanhamento contínuo por <strong>15 dias</strong> para esclarecer dúvidas e monitorar seu progresso.</p>
        <p>Você receberá um relatório da consulta ao final de todos os atendimentos.</p>
        <p>Caso você tenha exames realizados para apresentar no retorno, é só enviar os resultados para o e-mail <strong>exames@olissaude.com</strong> e nossa Equipe de Cuidados enviará o link para agendamento.</p>
      `,
    },
    {
      number: "Nine",
      fistClassAdd: "collapsed",
      question: "Política de cancelamento",
      answer: `
        <p><strong>Política de Cancelamento e Reagendamento de Consultas</strong></p>
        <h4>1. Cancelamento de Consultas:</h4>
        <ol>
          <li>O cancelamento da consulta pode ser realizado sem ônus até 24 horas antes do horário agendado.</li>
          <li>Cancelamentos fora do prazo estabelecido poderão implicar em cobrança integral da consulta.</li>
        </ol>
        <h4>2. Reagendamento de Consultas:</h4>
        <ol>
          <li>O reagendamento da consulta pode ser feito até 3 horas antes do horário agendado, sem custo adicional.</li>
          <li>Reagendamentos feitos dentro do prazo estabelecido serão acomodados sem prejuízo ao cliente ou ao profissional responsável.</li>
        </ol>
        <h4>3. Procedimento para Cancelamento ou Reagendamento:</h4>
        <ol>
          <li>O cancelamento ou reagendamento deve ser realizado através da plataforma online.</li>
          <li>Em caso de dificuldades técnicas ou outras questões, o cliente pode entrar em contato diretamente com nosso suporte ao cliente para assistência.</li>
        </ol>
        <h4>4. Responsabilidades do Cliente:</h4>
        <ol>
          <li>É responsabilidade do cliente verificar e confirmar o agendamento da consulta.</li>
          <li>Alterações ou cancelamentos devem ser comunicados dentro do prazo estabelecido para evitar custos adicionais.</li>
        </ol>
        <h4>5. Contato e Suporte:</h4>
        <p>Para quaisquer dúvidas ou informações adicionais sobre nossa política de cancelamento e reagendamento, entre em contato conosco através do e-mail <strong>cancelamentos@olissaude.com</strong>.</p>
        <p><strong>Observação:</strong> Esta política foi elaborada de acordo com as diretrizes do Código de Defesa do Consumidor e está sujeita a alterações sem aviso prévio. Quaisquer mudanças serão comunicadas aos clientes através de nossos canais de comunicação.</p>
      `,
    },
  ];

  return (
    <>
      <div
        className="section-faq-area pt-120 pb-100"
        style={{ background: "#d5c5e6" }}
      >
        <div className="container">
          <div className="row" style={{ justifyContent: "center" }}>
            <div className="col-xl-8 col-lg-12">
              <div className="about-title mb-45">
                <h5 className="pink-color">Perguntas Frequentes</h5>
                <h1>Tudo o que você precisa saber</h1>
              </div>
              <div className="faq-right-box">
                <div id="accordion" className="mt-40">
                  {faqData.map((faq, index) => (
                    <HomeThreeSingleFaq
                      key={index}
                      number={faq.number}
                      isOpen={openFaqIndex === index}
                      onClick={() => handleToggle(index)}
                      question={faq.question}
                      answer={faq.answer}
                    />
                  ))}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default HomeThreeFaq;
