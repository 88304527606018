import React from "react";
import CommonPageHeader from "../../components/CommonPageHeader/CommonPageHeader";
import HomeFooter from "../Home/Home/HomeFooter/HomeFooter";
import HomeFact from "../Home/Home/HomeFact/HomeFact";
import HomeHeader from "../Home/Home/HomeHeader/HomeHeader";
import HomeOurTeam from "../Home/Home/HomeOurTeam/HomeOurTeam";
import AboutAnalysis from "./AboutAnalysis/AboutAnalysis";
import AboutAppoinment from "./AboutAppoinment/AboutAppoinment";
import AboutArea from "./AboutArea/AboutArea";
import HomeFiveHowWorks from "../HomeFive/HomeFiveHowWorks/HomeFiveHowWorks";
import HomeThreeFaq from "../HomeThree/HomeThreeFaq/HomeThreeFaq";

const PrimeiraConsulta = () => {
  const title = "A primeira consulta ginecológica";
  const descricao =
    "<i>Conectando adolescentes ao suporte que merecem.</i> </br></br> Sua primeira consulta a um clique de distância. A primeira consulta ginecológica é voltada para as Olis adolescentes, a partir da primeira menstruação.";
  const price = "R$ 359,00 ou até 6x de R$ 62,00";

  const fatos = {
    titulo_fatos: "É sobre se conhecer",
    descricao_fatos:
      "Vamos ter um papo sobre saúde feminina, como as mudanças corporais dessa fase, funcionamento do ciclo menstrual, TPM, sexo seguro entre outros assuntos, além de tirar todas suas dúvidas.",
    subtitulos: [
      {
        titulo: "Valorizamos sua autonomia e privacidade",
        subtitulo:
          "Essa consulta poderá ser realizada individualmente ou na presença de um acompanhante. Respeitamos o seu desejo e queremos que você se sinta confortável e a vontade.",
      },
      {
        titulo: "Cuidado Personalizado",
        subtitulo:
          "Para que nada fique esquecido, te enviaremos um relatório com todas as informações e condutas definidas na nossa consulta. Aproveite esse presente!",
      },
    ],
  };

  const passos = [
    {
      titulo: "Agendamento do serviço",
      descricao: "Escolha o melhor horário para você",
    },
    {
      titulo: "Recebimento do Link de Atendimento, via Whatsapp",
      descricao: "Receba o link de atendimento",
    },
    {
      titulo:
        "Envio de exames prévios via link recebido pelo WhatsApp, se houverem",
      descricao: "Realize os exames",
    },

    {
      titulo: "Consulta por videochamada (~30 min)",
      descricao: "Converse com a gente",
    },
    {
      titulo: "Retorno em até 30 dias para ver seus exames.",
      descricao: "Aguarde o retorno",
    },
    {
      titulo: "Envio de Relatório Médico",
      descricao: "Receba o relatório médico",
    },
  ];

  const idArea = 1;
  const idServico = 1;

  const imgUrl = "img/primeiraconsulta.svg";

  return (
    <>
      <HomeHeader />
      {/* <CommonPageHeader title="About Us" subtitle="About" /> */}
      <AboutArea
        title={title}
        descricao={descricao}
        price={price}
        idArea={idArea}
        idServico={idServico}
        imgUrl={imgUrl}
      />{" "}
      {/* <AboutCounter /> */}
      <HomeFact fatos={fatos} />
      <AboutAppoinment passos={passos} />
      {/* <HomeOurTeam /> */}
      <HomeThreeFaq />
      <HomeFooter />
    </>
  );
};

export default PrimeiraConsulta;
