import React, { useState } from "react";
import Slider from "react-slick";
import CommonPageHeader from "../../components/CommonPageHeader/CommonPageHeader";
import HomeFooter from "../Home/Home/HomeFooter/HomeFooter";
import HomeFact from "../Home/Home/HomeFact/HomeFact";
import HomeHeader from "../Home/Home/HomeHeader/HomeHeader";
import AboutAppoinment from "./AboutAppoinment/AboutAppoinment";
import AboutArea from "./AboutArea/AboutArea";
import HomeThreeFaq from "../HomeThree/HomeThreeFaq/HomeThreeFaq";
import MetodoCarrossel from "./Components/MetodoCarrossel";

const InsercaoTrocaDIUsImplanon = () => {
  const title = "Inserção / Troca DIUs e Implanon";
  const descricao = `Primeiro, marcaremos uma consulta online para  avaliarmos a indicação do método , com o custo de X reais. \n
    Em seguida, você agendará sua inserção do método escolhido, com os valores expostos abaixo:`;
  const price = "Consulta Pré-Inserção R$359,90 ou 6x de R$59,90 \n ";

  const fatos = {
    titulo_fatos: "Para quem pensa a longo prazo",
    descricao_fatos:
      "Para a mulher OLIS que está pensando a longo prazo e optou pela inserção/troca de DIU/Implanon, poderá agendar a inserção presencial após nossa consulta online.",
    subtitulos: [
      {
        titulo: "Paz de espírito por muito tempo",
        subtitulo:
          "Parabéns pela escolha do método contraceptivo de longa duração! Além de sua eficácia comprovada, você está garantindo um excelente custo-benefício, que oferece paz de espírito por muito tempo.",
      },
      {
        titulo: "Mais praticidade no dia a dia",
        subtitulo:
          "Colocação simples e rápida para uma proteção segura e duradoura. Em menos de 30 minutos, você garante uma proteção por 3, 5, ou até 10 anos.",
      },
    ],
  };

  const idArea = 1;
  const idServico = 27;

  const passos = [
    {
      titulo: "Agendamento da consulta",
      descricao: "Escolha o melhor horário para você",
    },
    {
      titulo: "Recebimento do Link de Atendimento, via Whatsapp",
      descricao: "Receba o link de atendimento",
    },
    {
      titulo:
        "Envio de exames prévios via link recebido pelo WhatsApp, se houverem",
    },
    {
      titulo: "Consulta por videochamada (~ 45min)",
      descricao: "Converse com a gente",
    },
    {
      titulo: "Solicitação de exames complementares, se necessário",
    },
    {
      titulo: "Agendamento de inserção de DIU/implanon presencial - São Paulo",
    },
    {
      titulo: "Retorno em até 30 dias para avaliação do método",
    },
  ];

  const imgUrl = "img/diuimplanon.png";

  const tabelaValores = [
    {
      imgUrl: "img/diu.png",
      metodo: "DIU com hormônio (Mirena/Kyleena)",
      url: "/agendamento/1/27",
      eficacia:
        "998 mulheres protegidas a cada 1000 que inseriram corretamente",
      preco: "R$ 2350,00",
      durabilidade: "5 anos",
      custoPorDia: "R$ 1,28",
      mecanismo:
        "Espessa o muco cervical, impede que o espermatozoide progrida e alcance o óvulo.",
      insercao: "Em consultório médico cerca de 15-20 min",
    },
    {
      imgUrl: "img/diu.png",
      metodo: "DIU sem hormônio (Cobre/Cobre + Prata)",
      url: "/agendamento/1/27",
      eficacia:
        "994 mulheres protegidas a cada 1000 que inseriram de forma correta",
      preco: "R$ 1550,00",
      durabilidade: "5-10 anos",
      custoPorDia: "R$ 0,42-0,82",
      mecanismo:
        "Cobre: ação espermicida. Inativa espermatozóides impedindo a fecundação e implantação na parede uterina.",
      insercao: "Em consultório médico cerca de 15-20 min",
    },
    {
      imgUrl: "img/braco.png",
      metodo: "Implante hormonal (Implanon)",
      url: "/agendamento/1/27",
      eficacia:
        "999,5 mulheres protegidas a cada 1000 que inseriram corretamente",
      preco: "R$ 2100,00",
      durabilidade: "3 anos",
      custoPorDia: "R$ 1,91",
      mecanismo:
        "Bloqueia a ovulação, torna o muco cervical mais espesso, diminui a espessura do tecido endometrial.",
      insercao: "Em consultório médico cerca de 15-20 min",
    },
  ];

  const [selectedMethod, setSelectedMethod] = useState(null);

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
  };

  const handleCardClick = (method) => {
    setSelectedMethod(method);
  };

  return (
    <>
      <HomeHeader />
      <AboutArea
        title={title}
        descricao={descricao}
        price={price}
        idArea={idArea}
        idServico={idServico}
        imgUrl={imgUrl}
      />
      <HomeFact fatos={fatos} />
      <AboutAppoinment passos={passos} />
      <MetodoCarrossel metodos={tabelaValores} />

      <HomeThreeFaq />
      <HomeFooter />
    </>
  );
};

export default InsercaoTrocaDIUsImplanon;
